/* You can add global styles to this file, and also import other style files */
/* add style pagination */
.ngx-pagination .current {
    background: #36b1a0 !important;
    border: transparent !important;
    border-radius: 2px !important;
}

.ngx-pagination a,
.ngx-pagination button {
    border-radius: 2px !important;
    display: block !important;
}

.ngx-pagination .current:hover {
    background: #36b1a0 !important;
    border-radius: 2px !important;
    border: transparent !important;
}